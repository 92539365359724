export const environment = {
  production: true,
  environmentName:'test',
  apiUrl:'https://studioapi.tv2zdev.com/api/',
  socialUrl:'https://studioapi.tv2zdev.com/api/',
  version:'v2/',
  v3_version: 'v3/',
  routeUrl:'https://studio.tv2zdev.com/',
  cookieDomain:'tv2zdev.com',
  paywallURL: 'https://fbltestssr.tv2zdev.com/',
  appName: 'filmbox',
  appVersion: '1.0.0',
  htmlAppName: 'film1',
};