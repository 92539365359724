import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Router, RouterEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BaseService, SettingsService, StorageService } from './core/services';
import { ConfigurationService } from './core/services/configuration.service';
import { AnimationOptions } from 'ngx-lottie';
import { filter } from 'rxjs/operators';
import { SeoService } from './core/services/seo.service';
import { SubscriptionService } from './core/services/subscription.service';
import { environment } from 'src/environments/environment';
import { CategoriesService } from './core/services/categories.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Tv2z-app';
  showAndroidApp: boolean;
  showIosApp: boolean;
  loading = true;
  appName;
  options: AnimationOptions = {
    path: '/assets/' + environment.appName + '/loader.json',
  };
  hasHistory: boolean;
  pageH1Tag: any;
  guest: boolean;
  checkPaymentUpdate: boolean;
  showUpdateCard: boolean;

  constructor(private router: Router, public settingsService: SettingsService,
              private baseService: BaseService, private configService: ConfigurationService, private cdRef: ChangeDetectorRef,
              public route: ActivatedRoute, private seoService: SeoService,private storageService: StorageService,
              private subscriptionService: SubscriptionService, private categoryService: CategoriesService) {
    this.showAndroidApp = this.baseService.showAndroidApp;
    this.showIosApp = this.baseService.showIosApp;
    this.appName = environment.appName;

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(
        (event: RouterEvent) => {
          if (!this.showAndroidApp && !this.showIosApp && this.storageService.getLocalStore('a_t')
          && this.storageService.getLocalStore('r_t') && !this.categoryService.fetchCategoryData &&
          this.settingsService.showHeader) {
            this.categoryService.getNextVersionCategories().subscribe();
          }
          this.handleUTM();
          this.settingsService.getSeo();
          window.scrollTo(0, 0);
          const locationPathName = location.pathname;
          if(!locationPathName.includes('payment/status')) this.checkPayment(this.guest, this.checkPaymentUpdate);
        });
    this.baseService.loaderSource.subscribe(data => {
      this.loading = data;
    });
    this.seoService.pageH1Tag.subscribe(data => {
      this.pageH1Tag = data;
    });
    if (this.settingsService.metroOnly) this.settingsService.showHeader = false;
    this.settingsService.showFooter = true;
    this.baseService.guest.subscribe(data => {
      this.guest = data;
    });
    this.baseService.paymentUpdate.subscribe(data => {
      this.checkPaymentUpdate = data;
    })
    this.baseService.showCardData.subscribe(data => {
      this.showUpdateCard = !data?.no_past_due;
    })
  }

  checkPayment(guest, checkPaymentUpdate) {
    const uId = this.storageService.getLocalStore('u_id');
    if (uId && checkPaymentUpdate) {
      const postData = {
        u_id: uId,
        d_type: 'web'
      }
      this.subscriptionService.updateRedirect(postData).subscribe((data) => {
        if (data) {
          const updateData = {
            no_past_due: data.no_past_due,
            session_id: data.session_id
          };
          this.baseService.showCardData.next(updateData)
        } else {
          this.baseService.showCardData.next({ no_past_due: true, session_id: undefined })
        }
      })
    }
  }
  handleUTM(){
    let params = new URLSearchParams(location.search);
    const url_utm_source = params.get('utm_source');
    const url_utm_medium = params.get('utm_medium');
    const url_utm_campaign = params.get('utm_campaign');
    const session_utm_source = this.storageService.getSessionStore('utm_source');
    const session_utm_medium = this.storageService.getSessionStore('utm_medium');
    const session_utm_campaign = this.storageService.getSessionStore('utm_campaign');
    const utm_session_exists = session_utm_campaign || session_utm_medium || session_utm_source;
    const url_utm_exists = url_utm_campaign || url_utm_medium || url_utm_source;
    if(url_utm_exists){
      if(url_utm_source){
        this.storageService.setSessionStore('utm_source', url_utm_source);
      }
      if(url_utm_medium){
        this.storageService.setSessionStore('utm_medium', url_utm_medium);
      }
      if(url_utm_campaign){
        this.storageService.setSessionStore('utm_campaign', url_utm_campaign);
      }
    }else if(utm_session_exists){
      this.settingsService.setUTMFromSession();
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    if(this.appName === 'dizi') this.domLoaded();
    this.loading = false;
  }

  domLoaded() {
    this.loading = false;
  }

}
