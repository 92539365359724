export const TERMS = 'terms';
export const PRIVACY = 'privacy';
export const COUPON  = 'coupon';
export const VOUCHER = 'voucher';
export const K_GOOGLE_CAPTCHA = '6LeZ5ZcaAAAAABydsaWn1aaylFf27nSwSkFQJSrf';
export const K_QUERY_PARAM_TV = 'tv';
export const K_QUERY_PARAM_APPLE_TV = 'activate-tv';
export const videojsBtnAlign = {
    // controlBar: {
    //   children: [
    //       "playToggle",
    //       "qualityMenu",
    //       "progressControl",
    //       "SubsCapsButton",
    //       "currentTimeDisplay",
    //       "timeDivider",
    //       "durationDisplay",
    //       "fullscreenToggle",
    //   ]
    // }
  };
