import { environment } from 'src/environments/environment';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { BaseService, SettingsService, StorageService } from 'src/app/core/services';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { ContentService } from 'src/app/core/services/content.service';
import { DatalayerService } from 'src/app/core/services/datalayer.service';
import { HomeService } from 'src/app/core/services/home.service';
import { PlayContentService } from 'src/app/core/services/play-content.service';
import * as $ from 'jquery';
import { WishlistService } from 'src/app/core/services/wishlist.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './' + environment.htmlAppName + '/card-list.component.html',
  styleUrls: ['./' + environment.appName + '/card-list.component.scss']
})
export class CardListComponent implements OnInit, OnChanges, AfterViewInit {


  @Input() cards;
  @Input() numCardsInRow: number;
  @Input() contentStatus;
  hovered: number = -1;
  @Input() searchComp;
  @Input() type;
  @Input() isSearch;
  @Input() searchTerm;
  @Input() allData;
  breadCrumb: any;
  appName = '';
  genreCurationEnabled;
  ngOnChanges() {
    this.setNumCards();
  }

  constructor(public settingsService: SettingsService, public baseService: BaseService,
    public playContentService: PlayContentService, private contentService: ContentService,
    private storageService: StorageService, private datalayerService: DatalayerService, public dialog: MatDialog,
    private router: Router, private homeService: HomeService, public configService: ConfigurationService, 
    private wishlistService: WishlistService, private changeDetector: ChangeDetectorRef, private route: ActivatedRoute) {
      this.genreCurationEnabled = this.settingsService.genreCurationEnabled;
  }

  ngOnInit(): void {
    this.appName = environment.appName;
    this.breadCrumb = {
      menu_item: this.route.snapshot.queryParams.menu_item,
      slug: this.route.snapshot.queryParams.slug
    };
    if (this.allData && this.allData.group) this.breadCrumb.submenu_title = this.allData.group.title_translation_key
    this.changeDetector.detectChanges();
    this.homeService.contentWishlistData.subscribe((dataWishlist) => {  
      if(this.contentStatus) {
        this.cards = dataWishlist;
      }
        this.homeService.contentWishlist.subscribe((data) => {
          if (this.cards?.length && data?.length) {
            this.cards.forEach(banner => {
              if (banner.is_series) {
                banner.wishlist = data.includes(banner.series_slug);
              } else {
                banner.wishlist = data.includes(banner.content_slug);
              }
            });
          } else {
            this.cards.forEach(banner => {
              banner.wishlist = false;
            });
          }
          this.baseService.loaderSource.next(false);
        });
    });
  }

  alignHoverCard() {
    const modValue = (this.hovered % this.numCardsInRow);
    if (this.hovered > -1) {
      switch (modValue) {
        case 0:
          return 'translateX(79px)';
        case (this.numCardsInRow - 1):
          return 'translateX(-79px)';
        default:
          return 'translateX(0px)';
      }
    }
  }

  setNumCards() {
    const cardAlignDiv = document.getElementById('card-align');
    if (cardAlignDiv) {
      cardAlignDiv.setAttribute('style', `grid-template-columns:repeat(${this.numCardsInRow}, ${1}fr)`);
    }
  }

  ngAfterViewInit() {
    this.setNumCards();
  }

  addGTMData(video) {
    const datalayerData = {
      event: 'GAEvent',
      eventCategory: 'Content Click',
      eventAction: (video.collection_title) ? video.collection_title : video.seasons_title,
      eventLabel: video.title,
      loginStatus: (this.storageService.getLocalStore('u_id') ? 'True' : 'False'),
      userId: (this.storageService.getLocalStore('u_id') ? this.storageService.getLocalStore('u_id') : ''),
      content: this.datalayerService.getCategoryDatalayer(video, 'content'),
      contentId: video.contentId,
      VideoCategory: this.datalayerService.getCategoryDatalayer(video, 'category'),
      notificationStatus:  localStorage.getItem('emailNotify') ||  'False',
      region: (this.storageService.getLocalStore('region') ? this.storageService.getLocalStore('region') : ''),
      platform: this.baseService.browserType,
      userType: this.storageService.getLocalStore('user_type'),
      operator: this.storageService.getLocalStore('operator_name')
    };
    this.datalayerService.addDatalayer(datalayerData);
  }

  addToWishlist(data){
    this.playContentService.addToWishlist(data);
  }
  removeFromWishlist(data){
    this.wishlistService.removeFlagOn = false;
    this.homeService.removeListFlage = true;
    this.playContentService.removeList = true;
    this.playContentService.removeWishlist(data);
    let response = {
      title: 'wishlist'
    }
    if(this.wishlistService.seekCount > this.settingsService.takeValue){
      this.homeService.userContent(response).subscribe(data => {
      })
    }
  }

  redirect(video) {
      if (this.dialog.openDialogs.length > 0) {
        const clearInput = <HTMLInputElement> document.querySelector(".search-field");
        clearInput.value = '';
        const showSearch = <HTMLInputElement> document.getElementById("searchIcon");
        const showCancel = <HTMLInputElement> document.getElementById("cancelIcon");
        showCancel.style.opacity = '0';
        showSearch.style.opacity = '1';
        showCancel.style.zIndex = '0';
        showSearch.style.zIndex = '1';
        const inputBox = <HTMLInputElement> document.getElementById("inputSearch");
        if(window.innerWidth > 767 && window.innerWidth < 1001){
          inputBox.setAttribute('style', 'width: 23px ');
          document.getElementById('listCategory').style.display = 'block';
          $('#inputSearch').blur();
        } else {
          inputBox.setAttribute('style', 'width: 280px ')
        }
        this.dialog.closeAll();
      }
      this.addGTMData(video);
      if (this.type === 'genreList') {
        const navigationExtras: NavigationExtras = {
          queryParams: {
            menu_item: this.route.snapshot.queryParams.menu_item,
            submenu_title: this.allData.group.title_translation_key,
            slug: this.allData.group.slug
          }
        };
        this.router.navigate(['/collection', video.slug], navigationExtras);
      } else if (video.is_livechannel) {
        this.baseService.storeEPGValue(video.slug);
      } else if (video.is_series) {
        const seriesSlug = video?.seasons_slug || video?.series_slug;
        const contentSlug = video?.content_slug;
        if (video.watched_duration) {
          this.contentService.contentSlug = contentSlug;
          this.contentService.seriesSlug = seriesSlug;
          this.contentService.seekValue = video.watched_duration;
        } else {
          this.contentService.seekValue = 0;
        }
        this.router.navigate(['/series', seriesSlug, contentSlug]);
      } else {
        this.router.navigate(['/video', video.collection_slug, video.content_slug]);
      }
  }

}
