import { Injectable } from '@angular/core';
import { SettingsService } from './settings.service';
import { StorageService } from './storage.service';

declare global {  interface Window {_paq: any; } }
@Injectable({
  providedIn: 'root'
})

export class DatalayerService {

  gtmEnabled;
  constructor(private storageService: StorageService) { }

  addDatalayer(data: any){
    if(this.gtmEnabled) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    }
    // console.log(window.dataLayer);
  }

  addMediaAnalytics(){
    window._paq.push(['MediaAnalytics::enableMediaAnalytics']);
    window._paq.push(['MediaAnalytics::enableTrackEvents']);
    window._paq.push(['MediaAnalytics::enableTrackProgress']);
    window._paq.push(['MediaAnalytics::scanForMedia']);
  }

  getCategoryDatalayer(video: any, type:any){
    var collection_val = (video.collection_title) ? video.collection_title : video.seasons_title;
    
    if(video.is_series == '1') {
      var category = 'VOD - Series';
      var content_cat = 'Series';
    } else if(video.is_livechannel == '1') {
      var category = 'Live Channel';
      var content_cat = 'Live Channel';
      var collection_val = video.title_en;
    } else {
      var category = 'VOD - Movies';
      var content_cat = 'Movies';
    }
    if(type == 'category') {
        return category +' - ' + collection_val;
    } else {
        return content_cat+'|'+video.title_en+'|'+this.storageService.getLocalStore('localeName');
    }
  }

}
