import { environment } from 'src/environments/environment';
import { Component, OnInit, Inject, OnDestroy, HostListener } from '@angular/core';
import { BaseService, SettingsService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { SearchService } from 'src/app/core/services/search.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { AnimationOptions } from 'ngx-lottie';
export interface DialogData {
  return;
}

@Component({
  selector: 'app-search',
  templateUrl: './' + environment.htmlAppName + '/search.component.html',
  styleUrls: ['./' + environment.appName + '/search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  slideToShow: number;
  slideToScroll: number;
  slideToShowSeries: number;
  slideToScrollSeries: number;
  windowWidth: number;
  searchKey;
  searchActive: boolean;
  data: any[];
  searchCount;
  noResultsMessage: string;
  searchOpenedFirst = true;
  imagePath: any;
  searchName;
  numCardsInRow;
  searchData = [];
  tagsLength;
  showMoreResult = false;
  originalResult;
  showLessButton = false;
  options: AnimationOptions = {
    path: 'assets/search-loader.json'
  };
  overrideLoader = false;
  takeValue: number;
  paginate = false;
  executed = 0;
  search_count = 0;
  skip = 0;
  tempData = [];
  appName = '';
  totalGetCount = 0;
  dynamicName = '';

  constructor(public settingsService: SettingsService, private router: Router,
    private searchService: SearchService, public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData, public configService: ConfigurationService,
    private baseService: BaseService) {
      this.takeValue = 12 || this.settingsService.takeValue;
  }

  ngOnInit(): void {
    this.appName = environment.appName;
    this.settingsService.showHeader = true;
    this.settingsService.showFooter = false;
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.position = 'fixed';
    headerTop.style.marginRight = '18px';
    this.imagePath = this.settingsService.imagePath;
    this.windowWidth = window.innerWidth;
    this.setNumCards();
    this.baseService.searchData.subscribe(search => {
      this.searchName = search;
      this.getSearchData(this.searchName, 0, this.takeValue);
    });
    document.getElementById("inputSearch").focus();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setNumCards();
  }
// this method using divided base on the movie grids
  setNumCards() {
    const windowWidth: number = window.innerWidth;
    /*
    * Padding left and right - 169px
    * Column gap - 98px
    * Card width - 302px
    */
    this.numCardsInRow = Math.floor((windowWidth - ((169 * 2) - 98)) / (302 + 98));
  }
// this method using to close the search text
  closeSearch() {
    this.searchActive = false;
    this.data = [];
    this.searchCount = '';
    this.totalGetCount = 0;
    this.dynamicName = '';
    this.searchKey = undefined;
    this.noResultsMessage = '';
    this.dialogRef.close();
    this.settingsService.showFooter = true;
    this.settingsService.showHeader = true;
  }
// this method for get the search text details
  getSearchData(key, skip, take) {
    if(this.dynamicName == ''){
      this.dynamicName = key
    }else{
      if(this.dynamicName != key) this.totalGetCount = 0;
    }
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.zIndex = '1047';
    this.searchOpenedFirst = false;
    this.overrideLoader = true;
    this.paginate = true;
    this.searchService.getData(key, skip, take).subscribe(
      (data) => {
        if (data) {
          this.paginate = false;
          this.executed = 1;
          this.data = data.contents;
          this.searchCount = data.total;
          this.originalResult = data.contents[0].list;
          this.search_count = data.contents[0].list.length;
          this.totalGetCount += data.contents[0].list.length;
          this.overrideLoader = false;
          if(this.search_count > 0) {
            if(this.skip == 0) {
              this.searchData = data.contents[0].list;
            } else {
              this.tempData = this.searchData;
              this.tempData = this.removeDupliactes(this.tempData.concat(data.contents[0].list));
              this.searchData = this.tempData;
            }
          }
          this.searchData.map(data => {
            if (data.tags && typeof (data.tags) == 'string') {
              this.tagsLength = data.tags.length;
              return data.tags = data.tags.split(',');
            }
          });
          if (this.searchCount === 0 && this.totalGetCount === 0) {
            this.noResultsMessage = 'No search results Found';
          }
          if((this.skip + this.search_count) >= this.searchCount) {
            this.showLessButton = true;
            this.showMoreResult = false;
          } else {
            this.showLessButton = false;
            this.showMoreResult = true;
          }
        }
      }
    );
  }
// this method using to search content details.
  goToSearchPage = function (term) {
    if (term) {
      this.searchService.getData(term).subscribe(
        (data) => {
          if (data) {
            this.data = data.contents;
            this.searchCount = data.total;
            if (this.searchCount === 0) {
              this.noResultsMessage = 'No search results Found';
            }
          }
        }
      );
    }
  };
// this method using to if click more botton it's display the more related movie grides.
  openMore() {
    this.skip = this.search_count + this.skip;
    this.getSearchData(this.searchName, this.skip, this.takeValue);
  }

  showLess() {
    this.showLessButton = false;
    this.searchData = this.searchData.slice(0, 6);
  }

  ngOnDestroy() {
    const headerTop = <HTMLInputElement>document.querySelector(".navbar");
    headerTop.style.position = 'fixed';
    headerTop.style.marginRight = '0px';
    headerTop.style.zIndex = '1043';
    this.closeSearch();
  }
// this method for removing duplicate for content list details 
  removeDupliactes(values) {
      let concatArray = values.map(eachValue => {
        return Object.values(eachValue).join('')
      })
      let filterValues = values.filter((value, index) => {
        return concatArray.indexOf(concatArray[index]) === index
      })
      return filterValues
  }
}
