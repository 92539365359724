import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { K_GOOGLE_CAPTCHA } from 'src/app/shared/constants';
import { A_PAYWALL_BG, A_PAYWALL_BG_OVERLAY } from 'src/app/shared/constants/assets.constants';
import { environment } from 'src/environments/environment';
import { DatalayerService } from './datalayer.service';
import { SeoService } from './seo.service';
import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings;
  enableLive;
  siteLogo;
  siteIcon;
  homeBgImage;
  bannerHeading;
  bannerDescription;
  aboutSite;
  appstore;
  playstore;
  facebookLink;
  linkedinLink;
  twitterLink;
  pinterestLink;
  instagramLink;
  siteName;
  enablePolling;
  commentSection;
  seoUrl;
  enablePartnerVoucher;
  isSvodEnabled;
  isTvodEnabled;
  routeUrl;
  imagePath;
  stripeKey;
  displayLandingLocale;
  socialLink;
  socialAvail;
  webLogoUrl;
  commonBg;
  homeBg;
  showHeader = true;
  showFooter = true;
  logo: any;
  homeImage: any;
  facebookSignin;
  googleSignin;
  googleAnalytics;
  headerScripts;
  bodyScripts;
  pageH1Tag;
  pageTitleNew;
  pageDescription;
  pageKeyword;
  seo: any = {};
  virtualUrl;
  theoPlayerLibraryLocation: any;
  iosAppHref: any;
  androidAppHref: any;
  minimumWatchTime;
  enableCoupons: any;
  metroOnly;
  takeValue: number;
  userEmailNotified: any;
  googleRecaptcaKey: any;
  googleRecaptchaEnabled: any;
  paywallBgImage = A_PAYWALL_BG;
  paywallBgFallBackImage = A_PAYWALL_BG;
  paywallBgOverlay = A_PAYWALL_BG_OVERLAY;
  dEngageFlag: number;
  parentalControlModuleEnabled: number;
  geoBlockingFlag: any;
  player_language_options: any;
  s3_epg_path:any;
  epg_series_placeholder:any;
  epg_video_placeholder:any;
  epgChannelBgImage;
  display_epg_time_format:any;
  epgEnabled = 0;
  smartChannelsEnabled = 0;
  ezdrmWidevineLaurl;
  ezdrmPlayreadyLaurl;
  ezdrmFairplayCertificateUrl;
  ezdrmFairplayLaurl;
  enableTelco;
  youboraAccountCode;
  enableTrailers;
  enableCast;
  adsSupported;
  enableDrm;
  enableMatomoAnalytics;
  signInViaOperatorDisplay;
  displayOnTitle;
  churnPreventionFlowEnabled;
  displayQualityLevel;
  smartSignsEnabled;
  activateYourTvFromWebCode;
  activateYourTvFromTVCode;
  paywallEnabled;
  seoEnabled;
  gtmEnabled;
  isRelatedVod;
  genreCurationEnabled;
  loginWithMobileEnabled;
  youboraSiteName: any;
  showAuthPage = true;
  displayBannerTemplate;
  constructor(private http: HttpClient, private location: Location, private route: ActivatedRoute,
              private storageService: StorageService, private datalayerService: DatalayerService,
              private seoService: SeoService, private router: Router) { }

  settingFn(data: any) {
    this.settings = data;
    this.enableLive = +this.settings.enable_live_channels;
    this.siteIcon = this.settings.site_icon;
    this.homeBgImage = this.settings.home_page_bg_image;
    this.bannerHeading = this.settings.banner_heading;
    this.bannerDescription = this.settings.banner_description;
    this.aboutSite = this.settings.home_about_site;
    this.appstore = this.settings.appstore;
    this.playstore = this.settings.playstore;
    this.facebookLink = this.settings.facebook_link;
    this.linkedinLink = this.settings.linkedin_link;
    this.twitterLink = this.settings.twitter_link;
    this.pinterestLink = this.settings.pinterest_link;
    this.instagramLink = this.settings.instagram_link;
    this.siteName = this.settings.site_name;
    this.enablePolling = this.settings.enable_polling;
    this.commentSection = this.settings.comment_section;
    this.seoUrl = this.settings.seo_url;
    this.displayLandingLocale = +(this.settings.display_landing_locale ?? 0);
    this.googleAnalytics = this.settings.google_analytics;
    this.headerScripts = this.settings.header_scripts;
    this.bodyScripts = this.settings.body_scripts;
    this.dEngageFlag = +this.settings.d_engage_enable;
    if (this.settings.youbora_enabled === '1' && this.settings.youbora_enabled_devices.split(',').includes('web')) {
      this.youboraAccountCode = this.settings.youbora_code ? this.settings.youbora_code : 'filmboxdev';
      this.youboraSiteName = this.settings?.youbora_site_name ? this.settings.youbora_site_name : environment.appName;
    }
    this.parentalControlModuleEnabled = +(this.settings.parental_control_module_enabled ?? 0);
    this.geoBlockingFlag = this.settings.geo_blocking_enabled;
    if (this.settings.firebase_web_settings !== '') {
      // this.firebaseConfig = JSON.parse(this.settings.firebase_web_settings);
      // firebase.initializeApp(this.firebaseConfig);
    }
    this.routeUrl = this.settings.angular_site_url;
    this.imagePath = this.settings.image_base_path;
    this.webLogoUrl = this.imagePath + this.settings.web_logo;
    this.commonBg = this.imagePath + this.settings.common_bg_image;
    this.homeBg = this.imagePath + this.settings.home_page_bg_image;
    this.homeImage = this.imagePath + this.homeBgImage;
    this.logo = this.imagePath + this.settings.logo;
    this.siteLogo = this.imagePath + this.settings.web_logo;
    this.stripeKey = this.settings.stripe_key;
    this.storageService.setLocalStore('stripe_key', this.settings.stripe_key);
    if (this.stripeKey) {
      // angular.module('tv2zApp').config(function (StripeElementsProvider) {
      //   StripeElementsProvider.setAPIKey(this.stripeKey);
      // });
    }
    this.enablePartnerVoucher = this.settings.enable_partner_voucher;
    this.isSvodEnabled = +(this.settings.is_svod ?? 0);
    this.isTvodEnabled = this.settings.is_tvod;
    this.socialLink = {
      facebook: data.facebook_link,
      linkedin: data.linkedin_link,
      twitter: data.twitter_link,
      pinterest: data.pinterest_link,
      instagram: data.instagram_link,
      googlePlus: data.google_plus_link
    };
    this.socialAvail = this.socialLink.facebook || this.socialLink.linkedin || this.socialLink.twitter
      || this.socialLink.pinterest || this.socialLink.instagram || this.socialLink.googlePlus;
    this.facebookSignin = +(this.settings.facebook_signin ?? 0);
    this.googleSignin = +(this.settings.google_signin ?? 0);
    this.theoPlayerLibraryLocation = this.settings.theo_player_library_location;
    this.androidAppHref = this.settings.android_app_href;
    this.iosAppHref = this.settings.ios_app_href;
    this.minimumWatchTime = this.settings.minimum_duration_of_continue === ''? 10: +(this.settings.minimum_duration_of_continue);
    this.enableCoupons = this.settings.enable_coupons;
    this.metroOnly = +(this.settings.metro_only ?? 0);
    this.takeValue = this.settings.take_value ? +(this.settings.take_value) : 12;
    this.ezdrmWidevineLaurl= this.settings.ezdrm_widevine_laurl;
    this.ezdrmPlayreadyLaurl= this.settings.ezdrm_playready_laurl;
    this.ezdrmFairplayCertificateUrl= this.settings.ezdrm_fairplay_certificate_url;
    this.ezdrmFairplayLaurl = this.settings.ezdrm_fairplay_laurl
    this.userEmailNotified = this.settings.user_email_notified;
    this.googleRecaptchaEnabled = this.settings.google_recaptcha_enabled;
    if(this.settings.google_recaptcha_enabled === "1"){
      this.googleRecaptcaKey = this.settings.google_recaptcha_key || K_GOOGLE_CAPTCHA;
    }
    this.epgEnabled = +(this.settings.epg_enabled ?? 0);
    if(this.epgEnabled){
      this.s3_epg_path = this.settings.s3_epg_path;
      this.epg_series_placeholder = this.settings.epg_series_placeholder;
      this.epg_video_placeholder = this.settings.epg_video_placeholder;
      this.display_epg_time_format = this.settings.display_epg_time_format;
      this.epgChannelBgImage = this.settings.epg_channel_bg_image;
    }
    this.smartChannelsEnabled = +(this.settings.smart_channels_enabled ?? 0);
    this.genreCurationEnabled = +(this.settings.genre_curation_enabled ?? 0);
    this.player_language_options = this.settings.player_language_options;
    this.enableDrm = +(this.settings.enable_drm ?? 0);
    this.enableTelco = +(this.settings.enable_telco ?? 0);
    this.enableTrailers = +(this.settings.enable_trailers ?? 0);
    this.enableCast = +(this.settings.enable_cast ?? 0);
    this.adsSupported = +(this.settings.ads_supported ?? 0);
    this.enableMatomoAnalytics = +(this.settings.enable_matomo_analytics ?? 0);
    this.signInViaOperatorDisplay = +(this.settings.sign_in_via_operator_display ?? 0);
    this.displayOnTitle = +(this.settings.display_title_on_tile ?? 0);
    this.churnPreventionFlowEnabled = +(this.settings.churn_prevention_flow_enabled ?? 0);
    this.displayQualityLevel = +(this.settings.display_quality_levels_on_player ?? 0);
    this.smartSignsEnabled = +(this.settings.smart_signs_enabled ?? 0);
    this.activateYourTvFromWebCode = +(this.settings.activate_your_tv_from_web_code ?? 0);
    this.activateYourTvFromTVCode = +(this.settings.activate_your_tv_from_tv_code ?? 0);
    this.paywallEnabled = +(this.settings.paywall_enabled ?? 0);
    this.seoEnabled = +(this.settings.seo_enabled ?? 0);
    this.seoService.seoEnabled = this.seoEnabled;
    this.gtmEnabled = +(this.settings.gtm_enabled ?? 0);
    this.datalayerService.gtmEnabled = this.gtmEnabled;
    this.isRelatedVod = +(this.settings.is_related_vod ?? 0);
    this.loginWithMobileEnabled = +(this.settings.login_with_mobile_enabled ?? 0);
    this.displayBannerTemplate = this.settings.default_banner_v2_template;
    this.showAuthPage = !this.facebookSignin && !this.googleSignin;
  }

  get settingsData() {
    return this.settings;
  }

  slugify(input) {
    // make lower case and trim
    let slug = input.toLowerCase().trim();
    // replace invalid chars with spaces
    // slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, '-');
    return slug;
  }

  setUTMFromSession(){
    const session_utm_source = this.storageService.getSessionStore('utm_source');
    const session_utm_medium = this.storageService.getSessionStore('utm_medium');
    const session_utm_campaign = this.storageService.getSessionStore('utm_campaign');
    const utm_session_exists = session_utm_campaign || session_utm_medium || session_utm_source;
    if(utm_session_exists){
      let url = location.href;
      let utm_campaign = null;
      let utm_medium = null;
      let utm_source = null;
      if(session_utm_source) {
        if(url.includes('?')) {
            url =  url + '&utm_source=' + session_utm_source
            utm_source = session_utm_source;
        } else {
            url =  url + '?utm_source=' + session_utm_source
            utm_source = session_utm_source;
        }
      }
      if(session_utm_medium){
        url =  url + '&utm_medium=' + session_utm_medium
        utm_medium = session_utm_medium;
      }
      if(session_utm_campaign){
        url =  url + '&utm_campaign=' + session_utm_campaign
        utm_campaign = session_utm_campaign;
      }
      // window.location.href = url;
      this.router.navigate([],{
          relativeTo: this.route,
          queryParams: {utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign},
          queryParamsHandling: 'merge',
          replaceUrl: true
        });
    }
  }

  handleDataMartAnalyticsParamsData(){
    let utm_campaign = this.storageService.getSessionStore('utm_campaign');
    let utm_medium = this.storageService.getSessionStore('utm_medium');
    let utm_source = this.storageService.getSessionStore('utm_source');
    let data = {};
    if(utm_source){
      Object.assign(data, {'source': utm_source});
    }
    if(utm_medium){
      Object.assign(data, {'medium': utm_medium});
    }
    if(utm_campaign){
      Object.assign(data, {'campaign': utm_campaign});
    }
    let tvParams = JSON.parse(this.storageService.getSessionStore('tv_params'));
    if(tvParams){
      Object.assign(data, tvParams);
    }
    return data;
  }

  getUTMurl(url = null){
    url =  (url && this.settings.paywallEnabled ? environment.paywallURL: environment.routeUrl);
    const session_utm_source = this.storageService.getSessionStore('utm_source');
    const session_utm_medium = this.storageService.getSessionStore('utm_medium');
    const session_utm_campaign = this.storageService.getSessionStore('utm_campaign');
    const utm_session_exists = session_utm_campaign || session_utm_medium || session_utm_source;
    if(utm_session_exists){
      if(session_utm_source) {
        if(url.includes('?')) {
            url =  url + '&utm_source=' + session_utm_source
        } else {
            url =  url + '?utm_source=' + session_utm_source
        }
      }
      if(session_utm_medium){
        url =  url + '&utm_medium=' + session_utm_medium
      }
      if(session_utm_campaign){
        url =  url + '&utm_campaign=' + session_utm_campaign
      }
    }
    return url;
  }
  paramsHelper(paramsData){
    let params: HttpParams = new HttpParams();
      Object.keys(paramsData).forEach((key) => {
      params = params.append(key, paramsData[key]);
    });
    return params;
  }
  getSeo() {
    // if (Object.keys(this.seo).length === 0) {
    //   const headers: Headers = new Headers({});
    //   headers.append('Content-Type', 'application/x-www-form-urlencoded');
    //   headers.append('Content-Type', 'application/json');
    //   // this.http.get(this.seo_url).pipe(
    //   //   map((response) => {
    //   //     console.log("status: ", response)
    //   //     if (response['status'] = '200') {
    //   //       this.seo = response['data'];
    //   //       var pathname = location.pathname;
    //   //       var seourl = pathname.replace("/" + region + "/", '');
    //   //       if (this.seo.hasOwnProperty(seourl)) {
    //   //         this.pageH1Tag = this.seo[seourl]["h1"];
    //   //         this.pageTitleNew = this.seo[seourl]["title"];
    //   //         this.pageDescription = this.seo[seourl]["description"];
    //   //         this.pageKeyword = this.seo[seourl]["keywords"];
    //   //       } else {
    //   //         this.pageH1Tag = this.seo["default"]["h1"];
    //   //         this.pageTitleNew = this.seo["default"]["title"];
    //   //         this.pageDescription = this.seo["default"]["description"];
    //   //         this.pageKeyword = this.seo["default"]["keywords"];
    //   //       }
    //   //     }
    //   //     else
    //   //       console.log("Seo File not found");
    //   //   }, (error: HttpErrorResponse) => {
    //   //     console.log("Seo File not found");
    //   //   })
    //   // )
    // } else {
    //   const pathname = location.pathname;
    //   const seourl = pathname.replace('/' + region + '/', '');
    //   if (this.seo.hasOwnProperty(seourl)) {
    //     this.pageH1Tag = this.seo[seourl].h1;
    //     this.pageTitleNew = this.seo[seourl].title;
    //     this.pageDescription = this.seo[seourl].description;
    //     this.pageKeyword = this.seo[seourl].keywords;
    //   } else {
    //     this.pageH1Tag = this.seo.default.h1;
    //     this.pageTitleNew = this.seo.default.title;
    //     this.pageDescription = this.seo.default.description;
    //     this.pageKeyword = this.seo.default.keywords;
    //   }
    // }
    this.pageH1Tag = this.settings.h1_tag;
    this.pageTitleNew = this.settings.meta_title;
    this.pageDescription = this.settings.meta_description;
    this.pageKeyword = this.settings.meta_keywords;
    if (this.settings.meta_title) {
      this.seoService.setMetatitle(this.settings.meta_title);
    }
    if (this.settings.meta_keywords) {
      const metaData = { name: 'keywords', content: this.settings.meta_keywords };
      this.seoService.updateMetaTags(metaData);
    }
    if (this.settings.meta_description) {
      const metaData = { name: 'description', content: this.settings.meta_description };
      this.seoService.updateMetaTags(metaData);
    }
    if (this.settings.h1_tag) {
      this.seoService.pageH1Tag.next(this.settings.h1_tag);
    }
    this.seoService.metaTitle = this.settings.meta_title;
    this.seoService.keywords = this.settings.meta_keywords;
    this.seoService.description = this.settings.meta_description;
    let loginEvent = 'False';
    let userId = '';
    const autoUrl = this.route['_routerState']['snapshot'].url;
    const path = this.location.path();
    const absUrl = (autoUrl === '') ? this.routeUrl : autoUrl;
    this.virtualUrl = absUrl.substring(absUrl.indexOf(path));
    if (this.storageService.getLocalStore('u_id')) {
      loginEvent = 'True';
      userId = this.storageService.getLocalStore('u_id');
    }
    const datalayerData = {
      event: 'GAVirtual',
      pagePath: this.virtualUrl,
      pageTitle: (this.pageTitleNew ? this.pageTitleNew : 'Filmbox'),
      userId,
      loginStatus: loginEvent,
      userType: this.storageService.getLocalStore('user_type'),
      operator: this.storageService.getLocalStore('operator_name')
    };
    this.datalayerService.addDatalayer(datalayerData);
  }
}

export function getStripeKey() {
  return this.stripeKey;
}