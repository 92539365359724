import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService, SettingsService, StorageService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(private router: Router,private baseService: BaseService,
              private storageService:StorageService, private settingsService: SettingsService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const uId = this.storageService.getLocalStore('u_id');
    if((this.baseService.showAndroidApp || this.baseService.showIosApp) && !uId){
      if (this.settingsService.showAuthPage) this.router.navigateByUrl('/auth/signup');
      else this.router.navigateByUrl('/index/signup');
    } else {
      return true;
    }
  }
  
}
